import React from 'react';
import { connect } from 'react-redux';
import ProfilesPaypalSettings from './PaypalSettings'
import ProfilesPaymentSettings from './PaymentSettings'
class ProfilesPaymentWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.buttonCopy = this.buttonCopy.bind(this);
    this.descriptionCopy = this.descriptionCopy.bind(this);
    this.render = this.render.bind(this);
    this.unlock = this.unlock.bind(this);
  }

  render() {
    const {
      profile,
      i18n,
      paymentsOpen
    } = this.props;

    if(profile.payment_type == 'paypal') {
      return <ProfilesPaypalSettings />;
    }

    if(profile.payment_type == 'oracle' && paymentsOpen) {
      return <ProfilesPaymentSettings />;
    }

    return <div className='card-plus'>
      <div className='card-header'>
        {i18n.navigation.payment}
      </div>
      <div className='card-body'>
        <p dangerouslySetInnerHTML={{__html: this.descriptionCopy()}} />

        <button className='btn btn-primary' onClick={this.unlock}>
          {this.buttonCopy()}
        </button>
      </div>
    </div>;
  }

  descriptionCopy() {
    const {
      i18n,
      profile: {
        payment_vendor_status,
        payment_vendor_id
      }
    } = this.props;

    if(!payment_vendor_id) { // Record is new
      return i18n.descriptions.set_settings;
    }

    if(payment_vendor_status == 'Active' && payment_vendor_id) { // Record is valid
      return i18n.descriptions.update_settings;
    }

    // Record must be invalid
    return i18n.descriptions.fix_settings;
  }

  buttonCopy() {
    const {
      i18n,
      profile: {
        payment_vendor_status
      }
    } = this.props;

    if(payment_vendor_status == 'Active') {
      return i18n.buttons.update_payment;
    }

    return i18n.buttons.set_payment;
  }

  unlock(e) {
    e.preventDefault();

    this.props.togglePayments();
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      profile: state.profile,
      i18n: state.meta.i18n,
      paymentsOpen: state.paymentsOpen
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      togglePayments: () => dispatch({ type: 'TOGGLE_PAYMENTS' })
    };
  }
};

ProfilesPaymentWrapper.displayName = 'Profiles.PaymentWrapper';
export default connect(
  ProfilesPaymentWrapper.mapStateToProps,
  ProfilesPaymentWrapper.mapDispatchToProps
)(ProfilesPaymentWrapper);
