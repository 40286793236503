import React from 'react';

export default class HeaderAdminMenu extends React.Component {
  render() {
    const {
      community_admin,
      payment_access,
      shoppertunity_links,
      campaign_links,
      recruitment_links,
      system_settings_links,
      community_links,
      analytics,
      admin,
      cl,
      current_payments_path,
      community_manager,
      community_general_template,
      campaign_management_links,
      show_tags,
      feed_management,
      system_message,
      user_management,
      dashboard_user_management,
      payment_links,
      shoppertunity_management_links,
      create_payment,
      export_payments,
      all_shoppertunities_path,
      admin_feeds_path,
      manage_finanace_codes,
      my_shoppertunities_path,
      cl_shoppertunities_path,
      admin_member_applications_path,
      admin_recruitment_tags_path,
      admin_earned_media_lists_path,
      admin_analytics_path,
      edit_admin_platforms_path,
      admin_dashboard_path,
      admin_alerts_path,
      admin_campaigns_path,
      admin_community_round_tasks_path,
      admin_community_general_requirements_path,
      admin_community_instruction_notes_path,
      admin_groups_path,
      admin_shop_application_reasons_path,
      admin_users_path,
      admin_dashboard_users_path,
      admin_system_messages_path,
      admin_retailers_path,
      admin_community_tags_path,
      all_payments_path,
      pending_payments_path,
      locations_path,
      departments_path,
      expense_accounts_path,
      admin_communities_path,
      new_admin_pinterest_path
    } = this.props;

    const showClShops = cl && !shoppertunity_management_links;
    return (
      <div className="admin-dropdown-links">
        <div>
          {
            shoppertunity_links &&
            <section className="user_drop--link_section">
              <h4>Opportunities</h4>
              {shoppertunity_management_links &&
              <a href={all_shoppertunities_path}>All Opportunities<br/></a>}
              {shoppertunity_management_links && <a href={my_shoppertunities_path}>My Opportunities<br/></a>}
              {showClShops && <a href={cl_shoppertunities_path}>My Opportunities<br/></a>}
              {feed_management && <a href={admin_feeds_path}>Feeds<br/></a>}
            </section>
          }
          {
            campaign_links &&
            <section className="user_drop--link_section">
              <h4>Campaigns</h4>
              <a href={admin_campaigns_path}>All Campaigns<br/></a>
              { campaign_management_links && <a href={admin_recruitment_tags_path}>Recruitment Tags<br/></a> }
              {dashboard_user_management && <a href={admin_dashboard_users_path}>Dashboard Users<br/></a>} 
            </section>
          }
          {
            community_links &&
            <section className="user_drop--link_section">
              <h4>Community</h4>
              {community_admin && <a href={admin_communities_path}>All Communities</a>}
              {community_admin && <a href={admin_community_round_tasks_path}>Task Templates<br/></a>}
              {community_general_template && <a href={admin_community_general_requirements_path}>General Templates<br/></a>}
              {community_admin && <a href={admin_community_instruction_notes_path}>Instruction Notes Templates<br/></a>}
              {community_manager && <a href={edit_admin_platforms_path}>Platforms<br/></a>}
              {community_manager && <a href={admin_groups_path}>Groups<br/></a>}
              {show_tags && <a href={admin_community_tags_path}>Tags<br/></a>}
              {community_admin && <a href={admin_shop_application_reasons_path}>Opportunity App Reasons<br/></a>}
              {user_management && <a href={admin_users_path}>Social Fabric Users<br/></a>}
              {system_message && <a href={admin_system_messages_path}>System Wide Message<br/></a>}
              {community_admin && <a href={admin_retailers_path}>Retailers<br/></a>}
            </section>
          }
        </div>
        <div>
          {
            recruitment_links &&
            <section className="user_drop--link_section">
              <h4>Recruitment</h4>
              <a href={admin_member_applications_path}>Applications<br/></a>
              <a href={admin_recruitment_tags_path}>Recruitment Tags<br/></a>
            </section>
          }
          {
            system_settings_links &&
            <section className="user_drop--link_section">
              <h4>System Settings</h4>
              {analytics && <a href={admin_earned_media_lists_path}>Earned Media<br/></a>}
              {analytics && <a href={admin_analytics_path}>Analytics<br/></a>}
              {community_admin && <a href={edit_admin_platforms_path}>Platforms<br/></a>}
              {admin && <a href={admin_dashboard_path}>Active Admin<br/></a>}
              {admin && <a href={admin_alerts_path}>System Alerts<br/></a>}
            </section>
          }
          {
            payment_links &&
            <section className="user_drop--link_section">
              <h4>Payments</h4>
              {(create_payment || payment_access) && <a href={all_payments_path}>All Payments<br/></a>}
              {(create_payment || payment_access) && <a href={pending_payments_path}>Pending Payments<br/></a>}
              {!create_payment && !payment_access && <a href={all_payments_path}>My Payments<br/></a>}
              {export_payments && <a href={current_payments_path}>Current Payments<br/></a>}
              {manage_finanace_codes && <a href={locations_path}>Locations<br/></a>}
              {manage_finanace_codes && <a href={departments_path}>Departments<br/></a>}
              {manage_finanace_codes && <a href={expense_accounts_path}>Expense Accounts<br/></a>}
            </section>
          }
        </div>
      </div>
    )
  }
}

HeaderAdminMenu.displayName = 'Header.AdminMenu';
