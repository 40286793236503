import React from 'react';
import { connect } from 'react-redux';
import { FileUploader, Remote } from '../utils';
import FixedBar from '../FixedBar'
import ProfilesEdit from './Edit'

class ProfilesSaveBar extends React.Component {
  constructor(props) {
    super(props);

    this.formatData = this.formatData.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.nextTab = this.nextTab.bind(this);
    this.render = this.render.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.saveConnectedBlogs = this.saveConnectedBlogs.bind(this);
    this.savePaymentSettings = this.savePaymentSettings.bind(this);
    this.saveProfilePhoto = this.saveProfilePhoto.bind(this);
    this.saveText = this.saveText.bind(this);
  }

  render() {
    const i18n = this.props.meta.i18n;

    return <FixedBar zIndex={10} style='left-right'>
      <button className='btn btn-default' onClick={this.goBack}>
        <i className='fas fa-chevron-left'/> {i18n.buttons.profile_back}
      </button>
      <button className='btn btn-primary' id='saveChangesBtn' onClick={this.saveChanges} disabled={this.isDisabled()}>
        {this.saveText()}
      </button>
    </FixedBar>;
  }

  isDisabled() {
    const { frontendValidations, meta, activeTab } = this.props;
    const tab = meta.i18n.navigation
    const validations = frontendValidations[activeTab] || {};
    switch(activeTab) {
      case tab.demographics:
        return Object.values(validations).some(x => !!x) || Object.values(frontendValidations['Children'] || {}).some(x => !!x);
      case tab.connected_networks:
        return Object.values(validations['Blogs'] || {}).some(x => !!x) || this.props.saveChangesBtn;
      default:
        return Object.values(validations).some(x => !!x);
    }
  }

  formatData() {
    const {activeTab, meta, profile, blogs, deletedBlogIds} = this.props;

    const data = () => {
      switch(activeTab) {
      case meta.i18n.navigation.connected_networks:
        return { blog_platforms_attributes: blogs, deleted_blog_ids: deletedBlogIds};
      case meta.i18n.navigation.contact:
        return { ...profile };
      case meta.i18n.navigation.demographics:
        return { ...profile };
      case meta.i18n.navigation.payment:
        return { ...profile };
      case meta.i18n.navigation.basic:
      default:
        return { ...profile };
      }
    }

    return data();
  }

  nextTab(newProfileData = null) {
    const {
      meta: {
        i18n
      },
      profile,
      frontendValidations
    } = this.props;
    const hasNestedData = obj => Object.keys(obj || {}).some(x => !!x);

    const backendValidations = ProfilesEdit.tabValidations(i18n, newProfileData || profile);
    const backendTabs = Object.keys(backendValidations).filter(key => hasNestedData(backendValidations[key]));
    const frontendTabs = Object.values(frontendValidations).filter(key => hasNestedData(frontendValidations[key]));

    return _.uniq([...backendTabs, ...frontendTabs])[0];
  }

  saveText() {
     const i18n = this.props.meta.i18n;
     const activeTab = this.props.activeTab;

    if(this.nextTab() && this.nextTab() != activeTab) {
      return i18n.buttons.save_and_continue;
    } else {
      return i18n.buttons.save;
    }
  }

  saveChanges(e) {
    e.preventDefault();
    this.props.toggleLoading();
    const { meta, profile } = this.props;
    const activeTab = this.props.activeTab || meta.i18n.navigation.basic

    if (activeTab === meta.i18n.navigation.basic) {
      this.saveProfilePhoto();
    }

    // Payment settings have to be handled differently due to how they're processed
    // on the server side.
    if(activeTab === meta.i18n.navigation.payment && profile.payment_type == 'oracle') {
      return this.savePaymentSettings();
    }

    if(activeTab === meta.i18n.navigation.connected_networks) {
      return this.saveConnectedBlogs();
    }
    const params = $.param({
      api_action: `/profile/${meta.username}`
    });
    return Remote().request({
      method: 'PATCH',
      url: meta.proxyUrl + '?' + params,
      data: this.formatData(),

    }).then((response) => {
      this.props.toggleLoading();
      this.props.showSuccess(`Successfully saved ${activeTab}.`);
      this.props.updateBackendValidations(response.data.profile.validations);
      this.handleUsernameChange(response.data);
      if(this.nextTab(response.data.profile)) {
        this.props.setActiveTab(this.nextTab(response.data.profile));
      }
    }).catch((error) => {
      this.props.showError(this.props.meta.i18n.errors.ajax_failure);
      this.props.toggleLoading();
    });
  }

  saveProfilePhoto() {
    const { profile, meta } = this.props;

    if (profile && profile.profile_picture) {
      let formData = new FormData();
      formData.append('profile_picture', profile.profile_picture[0], profile.profile_picture[0].name);

      FileUploader().request({
        method: 'PATCH',
        url: `/profile/${meta.username}/upload_profile_picture`,
        data: formData,
      }).then((response) => {
        console.log(response)
      }).catch((error) => {
        console.log(error)
      });
    }
  }

  goBack(e) {
    e.preventDefault();
    window.location.href = this.props.meta.profileUrl;
  }

  // Payment settings go directly to weave to be processed by our Oracle pipeline,
  // instead of going to svc-api
  savePaymentSettings() {
    const paymentSettings = this.props.paymentSettings;
    // These are the only keys that should be sent to update payment settings
    const requiredKeys = [
      'first_name', 'middle_name', 'last_name', 'vendor_name', 'phone',
      'address1', 'address2', 'city', 'state', 'zip',
      'country', 'email', 'tax_id_number', 'name_1099', 'payment_notification',
      'ach_bank_routing_number', 'bank_account_number', 'bank_account_type',
      'bank_account_classification', 'federal_tax_classification'
    ];

    // We only want to send keys that are required
    const filtered = _.pickBy(this.props.paymentSettings, (value, key) => _.includes(requiredKeys, key));

    Remote().request({
      method: 'POST',
      url: this.props.meta.updatePaymentUrl + '.json',
      // Data is expected to be under a root :payment_settings_form key
      data: { payment_settings_form: filtered },
    }).then((response) => {
      const validations = this.props.profile.validations || {};
      const newProfile = {
        ...this.props.profile,
        // Assume it was successful and pull the payment validations out
        validations: _.pickBy(validations, (value, key) => key != 'payment_settings')
      };

      this.props.showSuccess('Successfully saved payment data.');
      this.props.updateBackendValidations(newProfile.validations);
      this.props.togglePayments();
      if(this.nextTab(newProfile)) {
        this.props.setActiveTab(this.nextTab(newProfile));
      }

      this.props.toggleLoading();
    }).catch((error) => {
    // Force an error state on the tab to show that something is wrong. This is necessary
      // since payment requests go through weave, so we don't have the same serialized response
      this.props.updateBackendValidations({
        ...this.props.profile.validations,
        payment_settings: {
          all: this.props.meta.i18n.errors.payment_update_failure
        }
      });
      this.props.showError(this.props.meta.i18n.errors.payment_update_failure);
      this.props.toggleLoading();
    });
  }

  saveConnectedBlogs() {
    this.props.toggleSaveChangesBtn();
    const { activeTab, meta } = this.props;

    const params = $.param({
      api_action: `/profile/${meta.username}/update_blogs`
    });

    return Remote().request({
      method: 'PATCH',
      url: meta.proxyUrl + '?' + params,
      data: this.formatData(),

    }).then((response) => {
      this.props.toggleLoading();
      this.props.showSuccess(`Successfully saved ${activeTab}.`);
      this.props.updateBlogs(response.data.blogs);
      this.props.updateBackendValidations(response.data.profile.validations);
      if(this.nextTab(response.data.profile)) {
        this.props.setActiveTab(this.nextTab(response.data.profile));
      }
      this.props.toggleSaveChangesBtn();
    }).catch((error) => {
      this.props.showError(this.props.meta.i18n.errors.ajax_failure);
      this.props.toggleLoading();
      this.props.toggleSaveChangesBtn();
    });
  }

  // If the username changes, we need to do the following:
  // 1. Update the current page URL so they can refresh the page without an error
  // 2. Update the username we have saved
  // 3. Update the payment url
  handleUsernameChange(newData) {
    const { meta } = this.props;
    if(newData.profile.username !== meta.username) {
      const newRoute = String(window.location).replace(meta.username, newData.profile.username);
      window.history.pushState({}, document.title, newRoute);
      this.props.updateMeta({
        username: newData.profile.username,
        updatePaymentUrl: meta.updatePaymentUrl.replace(meta.username, newData.profile.username),
        profileUrl: meta.profileUrl.replace(meta.username, newData.profile.username)
      });
      this.props.updateProfile('username', newData.profile.username);
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      profile: state.profile,
      paymentSettings: state.paymentSettings,
      blogs: state.blogs,
      deletedBlogIds: state.deletedBlogIds,
      frontendValidations: state.frontendValidations,
      activeTab: state.activeTab,
      meta: state.meta,
      i18n: state.meta.i18n,
      saveChangesBtn: state.saveChangesBtn
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      toggleLoading: () => dispatch({ type: 'TOGGLE_LOADING' }),
      setActiveTab: tab => dispatch({ type: 'SET_ACTIVE_TAB', value: tab }),
      showError: message => dispatch({ type: 'UPDATE_ALERT', value: { show: true, message, type: 'error' } }),
      showSuccess: message => dispatch({ type: 'UPDATE_ALERT', value: { show: true, message, type: 'success' } }),
      updateProfile: (field, value) => dispatch({ type: 'UPDATE_PROFILE', value: { field, value } }),
      updateMeta: change => dispatch({ type: 'UPDATE_META', value: change }),
      togglePayments: () => dispatch({ type: 'TOGGLE_PAYMENTS' }),
      updateBlogs: blogs => dispatch({ type: 'UPDATE_BLOGS', value: blogs }),
      toggleSaveChangesBtn: () => dispatch({ type: 'TOGGLE_SAVE_CHANGES' }),
      updateBackendValidations: validations => dispatch({
        type: 'UPDATE_PROFILE',
        value: {
          field: 'validations',
          value: validations
        }
      })
    };
  }
};

ProfilesSaveBar.displayName = 'Profiles.SaveBar';
export default connect(
  ProfilesSaveBar.mapStateToProps,
  ProfilesSaveBar.mapDispatchToProps
)(ProfilesSaveBar);
