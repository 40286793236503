import { createStore } from 'redux';
import _ from 'lodash'

const initialState = {
  meta: {},
  bloggerShopTask: {},
  contentType: {},
  submission: {
    draft: {}
  },
  preview: null,
  instagramAttachments: [],
  instagramMediaAttachments: [],
  tiktokAttachment: null,
  validations: [],
  readOnly: false,
  selected: [],
  content: {},
  contentUrl: null,
  loading: false,
  authFailure: {},
  social: {},
  manual: false,
  showAnnotations: false,
  totalContentCount: 0,
  disableSubmit: false
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
  case 'SET_META':
    return {
      ...state,
      meta: action.value
    };
  case 'SET_BLOGGER_SHOP_TASK':
    return {
      ...state,
      bloggerShopTask: action.value
    };
  case 'SET_CONTENT_TYPE':
    return {
      ...state,
      selected: [],
      content: null,
      contentType: action.value
    };
  case 'UPDATE_SUBMISSION':
    return {
      ...state,
      submission: {
        ...state.submission,
        ...action.value
      }
    };
  case 'RESET_SUBMISSION':
    return {
      ...state,
      submission: {}
    };
  case 'SET_PREVIEW':
    return {
      ...state,
      preview: action.value
    };
  case 'SET_INSTAGRAM_ATTACHMENTS':
    return {
      ...state,
      instagramAttachments: action.value
    }
  case 'ADD_INSTAGRAM_ATTACHMENT':
    return {
      ...state,
      instagramAttachments: [...state.instagramAttachments, action.value.attachment]
    }
  case 'DELETE_INSTAGRAM_ATTACHMENT':
    return {
      ...state,
      instagramAttachments: _.filter(state.instagramAttachments, attachment => !_.isEqual(attachment.id, action.value.attachment.id))
    }
  case 'SET_INSTAGRAM_MEDIA_ATTACHMENTS':
    return {
      ...state,
      instagramMediaAttachments: action.value
    }
  case 'SET_CONTENT_URL':
    return {
      ...state,
      contentUrl: action.value,
      submission: {
        ...state.submission,
        url: action.value
      }
    };
  case 'ADD_INSTAGRAM_MEDIA_ATTACHMENT':
    return {
      ...state,
      instagramMediaAttachments: [...state.instagramMediaAttachments, action.value.media]
    }
  case 'DELETE_INSTAGRAM_MEDIA_ATTACHMENT':
    return {
      ...state,
      instagramMediaAttachments: _.filter(state.instagramMediaAttachments, media => !_.isEqual(media.id, action.value.data.media.id))
    }
  case 'ADD_TIKTOK_ATTACHMENT':
    return {
      ...state,
      tiktokAttachment: action.value.attachment
    }
  case 'SET_TIKTOK_ATTACHMENT':
    return {
      ...state,
      tiktokAttachment: action.value
    }
  case 'DELETE_TIKTOK_ATTACHMENT':
    return {
      ...state,
      tiktokAttachment: initialState.tiktokAttachment
    };
  case 'SET_VALIDATIONS':
    return {
      ...state,
      validations: action.value
    };
  case 'RESET_VALIDATIONS':
    return {
      ...state,
      validations: []
    };
  case 'SET_CONTENT':
    return {
      ...state,
      content: _.flatten([action.value])
    };
  case 'RESET_CONTENT':
    return{
      ...state,
      bloggerShopTask: {
        ...state.bloggerShopTask,
        content: {
          ...state.bloggerShopTask.content
        }
      }
    };
  case 'SELECT_SINGLE_CONTENT':
    return {
      ...state,
      selected:  [{...action.value}]
    };
  case 'SELECT_MULTIPLE_CONTENT':
    return {
      ...state,
      selected:  [
        ...state.selected,
        {...action.value}
      ]
    };
  case 'DESELECT_CONTENT':
    return {
      ...state,
      selected: _.filter(state.selected, item => !_.isEqual(item, action.value))
    };
  case 'SET_READ_ONLY':
    return {
      ...state,
      readOnly: action.value
    };
  case 'UPDATE_DRAFT':
    return {
      ...state,
      submission: {
        ...state.submission,
        draft: {
          ...state.submission.draft,
          ...action.value
        }
      }
    };
  case 'SET_LOADING':
    return {
      ...state,
      loading: action.value
    };
  case 'SET_AUTH_FAILURE':
    return {
      ...state,
      authFailure: {
        ...state.authFailure,
        [action.value.type]: action.value.flag
      }
    };
  case 'SET_AUTH':
    return {
      ...state,
      social: {
        ...state.social,
        [action.key]: action.value
      }
    };
  case 'SET_MANUAL':
    return {
      ...state,
      manual: action.value
    };
  case 'TOGGLE_ANNOTATIONS':
    return {
      ...state,
      showAnnotations: action.value
    };
  case 'SET_ANNOTATIONS':
    return {
      ...state,
      submission: {
        ...state.submission,
        draft: {
          ...state.submission.draft,
          annotations: action.value
        }
      }
    };
  case 'TOGGLE_ANNOTATION_DONE':
    return {
      ...state,
      bloggerShopTask: {
        ...state.bloggerShopTask,
        content: {
          ...state.bloggerShopTask.content,
          draft: {
            ...state.bloggerShopTask.content.draft,
            annotations: state.bloggerShopTask.content.draft.annotations.map(x => {
              if(x.id != action.value) {
                return x;
              }

              return {
                ...x,
                done: !x.done
              };
            })
          }
        }
      }
    };
    case 'LOAD_MORE_CONTENT':
      return {
        ...state,
        content: [...state.content, ...action.value]
      };
    case 'SET_TOTAL_CONTENT_COUNT':
      return {
        ...state,
        totalContentCount: action.value
      };
  case 'TOGGLE_DISABLE_SUBMIT':
    return {
      ...state,
      disableSubmit: action.value
    }
  default:
    return state;
  }
};


const SubmissionStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.SubmissionStore' }) // Enable redux devtools middleware
);

export default SubmissionStore;