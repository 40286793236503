import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import { Remote } from '../utils';

import ProgressBar from '../ProgressBar'
import SubmissionBottomBar from './BottomBar'
import SubmissionAuthFailure from './AuthFailure'
import SubmissionManualContentSubmission from './ManualContentSubmission'
import SubmissionAnnotationDrawer from './AnnotationDrawer'
import SubmissionContentTypeSelector from './ContentTypeSelector'
import SubmissionFeedback from './SubmissionFeedback'
import SubmissionContentGrid from './ContentGrid'
import SubmissionInstagramDraftContent from './content_types/InstagramDraftContent'
import SubmissionTiktokDraftContent from './content_types/TiktokDraftContent'
import SubmissionBlogDraft from './content_types/BlogDraft'
import SubmissionLinkForm from './LinkForm'
import SubmissionAuthTypes from './AuthTypes'

class SubmissionShow extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadBloggerShopTask = this.loadBloggerShopTask.bind(this);
    this.renderSubmissionFeedback = this.renderSubmissionFeedback.bind(this);
    this.rejected = this.rejected.bind(this);
    this.latestInstagramDraftComment = this.latestInstagramDraftComment.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.loadBloggerShopTask();
    const screenWidth = window && window.innerWidth;
    this.props.setMeta({
      ...this.props,
      screenWidth
    });
  }

  render() {
    const task = this.props.bloggerShopTask;
    const type = this.props.contentType.name;
    const ready = this.props.social[SubmissionAuthTypes[type]] || this.props.manual;

    if(this.props.manual){
      return <div className='submission show'>
        {this.renderSubmissionFeedback()}
        <SubmissionManualContentSubmission {...this.props} displayPreview={true}/>
        <SubmissionBottomBar />
      </div>;
    }
    else if(!this.props.readOnly && type && this.props.authFailure[type] && !ready) {
      return <div className='submission show'>
        <SubmissionAuthFailure />
      </div>;
    } else if(type){
      return <div className='submission show'>
        {this.props.loading && <ProgressBar />}
        {this.renderSubmissionFeedback()}
        <div className='submission-columns'>
          <div className='submission-left'>
            {this.props.showAnnotations && <SubmissionAnnotationDrawer />}
          </div>
          <div className='submission-right'>
          {this.renderSubmissionForm()}
          </div>
        </div>
        <SubmissionBottomBar />
      </div>;
    } else {
      return <div className='submission show'>
        <SubmissionContentTypeSelector />
      </div>;
    }
  }

  renderSubmissionForm() {
    // Leave early in case we don't have the content type available quite yet
    if(!this.props.contentType.name) {
      return null;
    }

    switch(this.props.contentType.name) {
    case 'FacebookVideoContent':
    case 'YoutubeContent':
    case 'GoogleYoutubeContent':
    case 'InstagramContent':
    case 'InstagramReelContent':
    case 'InstagramStoryContent':
    case 'InstagramBusinessStoryContent':
    case 'TiktokVideoContent':
      return <SubmissionContentGrid tiktok_oembed_js={this.props.tiktok_oembed_js} />;
    case 'InstagramDraftContent':
      return <SubmissionInstagramDraftContent />;
    case 'TiktokDraftContent':
      return <SubmissionTiktokDraftContent />;
    case 'DraftContent':
      return <SubmissionBlogDraft />;
    default:
      return <SubmissionLinkForm/>;
    }
  }

  renderSubmissionFeedback() {
    const { content, contentType } = this.props;

    if(!content){
      return null;
    }

    const type = this.rejected() ? 'error' : 'primary';

    if(_.get(content, 'latest_content_validation.comment')) {
      return <SubmissionFeedback type={type} message={content.latest_content_validation.comment} timeStamp={content.latest_content_validation.created_at}/>
    } else if (contentType.name == 'InstagramDraftContent' && this.latestInstagramDraftComment()) {
      const instagramDraftComment = this.latestInstagramDraftComment()
      return <SubmissionFeedback type={type} message={instagramDraftComment.comment} timeStamp={instagramDraftComment.created_at}/>
    }

    return null;
  }

  rejected(){
    const { content } = this.props;

    if (!content)
      return false;

    const rejectedStates = [
      'rejected',
      'reject_new',
      'reject_edit'
    ];

    return _.some(rejectedStates, state => this.props.content.state === state)
  }

  latestInstagramDraftComment() {
    let comments = _.sortBy( this.props.content.instagram_draft_comments.filter(comment => comment.visible_to_influencer), (value) => new Date(value.created_at))

    if (comments.length > 0) {
      return {
        comment: comments[comments.length -1].comment,
        created_at: comments[comments.length -1].created_at
      }
    } else {
      return null
    }
  }

  loadBloggerShopTask() {
    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTaskId}`
    });

    Remote()
      .request({
        url: this.props.proxyUrl + "?" + params,
        method: "GET",
        converters: {
          // $.parseJson doesn't recurse far enough for this payload, so we need to
          // use the browser default here.
          "text json": JSON.parse,
        },
      })
      .then((response) => {
        this.props.setBloggerShopTask(response.data);
        if (response.data.round_task.content_types.length == 1) {
          this.props.setContentType(response.data.round_task.content_types[0]);
        }

        if (response.data.content) {
          this.props.updateSubmission({
            ...response.data.content,
            id: response.data.content.id,
            url: response.data.content.url,
            description2: response.data.content.description2,
            draft: response.data.content.draft,
            title: response.data.content.title,
            image_url: response.data.content.image_url,
          });

          const type = response.data.round_task.content_types.find(
            (type) => type.name == response.data.content.type
          );
          this.props.setContentType(type);

          const readOnlyStates = [
            "pending",
            "resubmitted",
            "pending_client_approval",
            "accepted",
            "rejected",
            "reject_new",
            "reject_edit",
            "client_denied",
            "client_accepted",
          ];

          if (
            _.some(
              readOnlyStates,
              (state) => response.data.content.state === state
            )
          ) {
            this.props.setReadOnly(true);
          }
        }
      });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      bloggerShopTask: state.bloggerShopTask,
      content: state.bloggerShopTask.content || myProps.content,
      contentType: state.contentType,
      readOnly: state.readOnly,
      loading: state.loading,
      authFailure: state.authFailure,
      social: state.social,
      manual: state.manual,
      showAnnotations:
        state.showAnnotations ||
        (state.meta.screenWidth > 1024 &&
          _.get(state.bloggerShopTask, "content.draft.annotations", [])
            .length != 0),
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setMeta: meta => dispatch({ type: 'SET_META', value: meta }),
      setBloggerShopTask: task => dispatch({ type: 'SET_BLOGGER_SHOP_TASK', value: task }),
      setContentType: type => dispatch({ type: 'SET_CONTENT_TYPE', value: type }),
      updateSubmission: params => dispatch({ type: 'UPDATE_SUBMISSION', value: params }),
      setReadOnly: readOnly => dispatch({ type: 'SET_READ_ONLY', value: readOnly })
    };
  }
};

SubmissionShow.displayName = 'Submission.Show';

export default connect(
  SubmissionShow.mapStateToProps,
  SubmissionShow.mapDispatchToProps
)(SubmissionShow);
